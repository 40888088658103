/* eslint-disable import/extensions */
import { BrowserCacheLocation } from '@azure/msal-browser';

const settings = require('./settings');

export const msalConfig = {
  auth: {
    clientId: settings.configClientId,
    authority: `https://login.microsoftonline.com/${settings.configTenantId}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // eslint-disable-next-line no-bitwise
    redirectUri: settings.configRedirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    iframeHashTimeout: 10000,
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [`api://${settings.configApiClientId}/access_as_user`],
  // eslint-disable-next-line no-bitwise
  redirectUri: settings.configRedirectUri,
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: settings.configHttpsEndpoint,
  subscriptionEndpoint: settings.configWssEndpoint,
};
