/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import jwt_decode from 'jwt-decode';
import { loginRequest, msalConfig } from './authConfig';

function validateToken(token) {
  const decodedToken: any = jwt_decode(token);
  const expiry = decodedToken.exp - 30 * 60;
  const currentTime = +new Date() / 1000;
  console.log('decodedToken.exp: ', decodedToken.exp, 'added expiry: ', expiry);
  console.log(currentTime, 'expiry - current > 0', expiry - currentTime);
  return expiry - currentTime > 0;
}

export function checkToken(instance) {
  const token = localStorage.getItem('token') || '';
  if (validateToken(token)) {
    console.log('token is valid');
    return;
  }
  const request = {
    ...loginRequest,
    account: instance.getAllAccounts()[0],
  };
  // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  instance
    .acquireTokenSilent(request)
    .then((response) => {
      localStorage.setItem(
        'token',
        response.accessToken ? response.accessToken : '',
      );
    })
    .catch((e) => {
      console.log('request token exception:', e);
    });
}

export function logoutInstance(instance) {
  localStorage.setItem('token', '');
  instance?.logoutRedirect({
    account: instance.getAllAccounts()[0],
    postLogoutRedirectUri: msalConfig.auth.redirectUri,
  });
}
