/* eslint-disable global-require */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-useless-escape */
/* eslint-disable no-plusplus */

export function register() {
  const webpush = require('web-push');
  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = `${process.env.PUBLIC_URL}/sw.js`; // new URL(process.env.PUBLIC_URL, window.location.href);
    // if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    //   return;
    // }
    function urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }
    function determineAppServerKey() {
      const vapidKeys = webpush.generateVAPIDKeys();
      console.log(
        'serviceWorker.ts ~ line 167 vapidKeys',
        vapidKeys,
        urlBase64ToUint8Array(vapidKeys?.publicKey),
      );
      return urlBase64ToUint8Array(vapidKeys?.publicKey);
    }
    navigator.serviceWorker.register(publicUrl).then((res) =>
      res.pushManager.getSubscription().then((subcription) =>
        res.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: determineAppServerKey(), //
        }),
      ),
    );
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
