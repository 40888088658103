// @ts-ignore
module.exports = {
  configHttpsEndpoint:
    'https://fde-dev-recon-global-01-gccqc5gdhjg4d8hg.z01.azurefd.net/api/graphql',
  configWssEndpoint:
    'wss://fde-dev-recon-global-01-gccqc5gdhjg4d8hg.z01.azurefd.net/api/graphql',
  configRedirectUri: 'https://dev-recon.azureedge.net/',
  // 'http://localhost:3000',
  configTenantId: 'a882bc25-e1dd-4721-b861-aba1afaec76d',
  configClientId: '610482f8-a97e-4a61-a24a-1714e6cc0910',
  configApiClientId: '9fda8ab0-44c1-4868-b28f-d75276d07233',
  configNotifyEndpoint:
    'https://func-eag-dev-recon-int-aue-01.azurewebsites.net/api',
};
