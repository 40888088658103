/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
import React, { lazy, Suspense } from 'react';
import { render } from 'react-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  HttpLink,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { isEmpty } from 'lodash';
import { graphConfig, msalConfig } from './authConfig';
import * as serviceWorker from './serviceWorker';
import Loader from './components/utility/loader.style';
import { checkToken, logoutInstance } from './authToken';

const App = lazy(() => import('./app'));
const rootEle = document.getElementById('root');
const msalInstance = new PublicClientApplication(msalConfig);

const httpLink = new HttpLink({
  uri: graphConfig.graphMeEndpoint,
});

const uploadLink = createUploadLink({
  uri: graphConfig.graphMeEndpoint,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  checkToken(msalInstance);

  const token = localStorage.getItem('token') || '';
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError((prop) => {
  const gE: any = prop?.graphQLErrors || [];
  if (!isEmpty(gE)) {
    gE.map((err) => {
      switch (err?.extensions?.code) {
        // Apollo Server sets code to UNAUTHENTICATED
        case 'UNAUTHENTICATED':
          // Modify the operation context with a new token
          return logoutInstance(msalInstance);
        default:
          return err;
      }
    });
  }
  // return null;
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  new GraphQLWsLink(
    createClient({
      url: graphConfig.subscriptionEndpoint,
    }),
  ),
  from([
    authLink.concat(errorLink),
    authLink.concat(uploadLink),
    authLink.concat(httpLink),
  ]),
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink, // authLink.concat(httpLink),
});

render(
  <Suspense fallback={<Loader />}>
    <ApolloProvider client={client}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </ApolloProvider>
  </Suspense>,
  rootEle,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
